.fb-bg{
    background-color: #f8f9fd;
    border-radius: 5px;
}

.fb-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    @media (max-width: 768px) {
        padding: 1rem;
    }

    .fb-card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 4rem 8rem 3rem;

        @media (max-width: 768px) {
            padding: 4rem 2rem 3rem;
        }

        .fb-icon{
            font-size: 5rem;
            font-weight: 900;
            box-shadow: inset 0px 0px 0px 8px #7680a4;
            padding: 1rem 4rem;
            color: #7680a4;
            border-radius: 50%; 

            @media (max-width: 768px) {
                box-shadow: inset 0px 0px 0px 7px #7680a4;
                padding: 0rem 3rem;
            }
        }

        .fb-msg{
            font-size: 0.9rem;
            padding: 2rem 3rem 3rem;
            width: 85%;
            text-align: center;

            @media (max-width: 768px) {
                padding: 2rem 0rem 3rem;
            }
        }

        .fb-btn{
            .fb-btn{
                border-style: none;
                background-color: #ffffff;
                padding: 0.5rem 2rem;
                border-radius: 3px;
                &.report{
                    background-color: #5E72E4;
                    color: #ffffff;
                    font-size: 1rem;
                    padding: 0.7rem 3rem;
                    transition: all 0.2s ease;
                    
                    &:hover{
                        background-color: #fff;
                        box-shadow: inset 0px 0px 0px 1px #5E72E4;
                        color: #5E72E4;
                    }
                }
            }
        }
    }
}