.explr-cards{

    .explr-card{
        padding: 1rem 1.5rem;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 7px 20px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

        .explr-i{
            position: absolute;
            right: 20px;
            top: 15px;
            border-radius: 50%;
            background-color: #ececec;
            width: 1.7rem;
            height: 1.7rem;
            text-align: center;
            line-height: 1.7rem;
            cursor: pointer;
            font-size: 0.8rem;
            font-weight: 600;
            color: #888888;
            box-shadow:#bdbdbd 0px 0px 0px 1.5px;
        }


        .explr-title{
            color: #5E72E4;
            font-weight: 700;
            i{
                margin-right: 0.5rem;
            }
        }

        .explr-body{
            padding: 0;

            .explr-checkbox{
                padding: 0 1.28rem;
                user-select: none;
                cursor: pointer;
            }
        }
    }
}

.explr-submit{
    padding: 1rem;
    display: flex;
    justify-content:flex-end;
    
    .btn{
        font-size: 1.1rem;
        background-color: #5E72E4;
        color: #ffffff;
    }
}

.explr-page{
    padding: 0rem 3rem 1rem;

    @media (max-width: 768px) {
        padding: 0rem 1rem 1rem;
    }
}